import store from '@/state/store'

export default [
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/pages/account/login'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'login' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('../views/pages/account/register'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'login' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/forgot-password',
        name: 'Forgot-password',
        component: () => import('../views/pages/account/forgot-password'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'login' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
            authRequired: false,
            beforeResolve(routeTo, routeFrom, next) {
                /*
                if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
                    store.dispatch('auth/logOut')
                } else {
                    store.dispatch('authfack/logout')
                }
                const authRequiredOnPreviousRoute = routeFrom.matched.some(
                    (route) => route.push('/login')
                )
                // Navigate back to previous page, or home as a fallback
                next(authRequiredOnPreviousRoute ? { name: 'home' } : { ...routeFrom })
                */
                if(localStorage.user){
                    localStorage.removeItem('user');
                }
                next({ name: 'login' });
            },
        },
    },
    {
        path: '/',
        name: 'home',
        meta: {
            authRequired: false,
        },
        component: () => import('../views/pages/dashboard/index')
    },
    {
        path: '/onboarding',
        name: 'onboarding',
        meta: {
            authRequired: false,
        },
        component: () => import('../views/pages/account/onboarding')
    },
    {
        path: '/pools',
        name: 'pools',
        meta: {authRequired: false,},
        component: () => import('../views/pages/tables/advancedtable'),
        props: { menu:"pools", entity: 'pool', addLink:"/pool", actions:{edit:true} }
    },
        {
        path: '/pool',
        name: 'pool',
        meta: { authRequired: false },
        component: () => import('../views/pages/management/pool'),
        props: { menu:"Company", entity: 'pool', id: null }
    },
    {
        path: '/pool/:id',
        name: 'poolEdit',
        meta: { authRequired: false },
        component: () => import('../views/pages/management/pool'),
        props: { id: null }
    },
    {
        path: '/schedules',
        name: 'schedules',
        meta: {authRequired: false,},
        component: () => import('../views/pages/tables/advancedtable'),
        props: { menu:"schedules", entity: 'schedule', addLink:"/schedule", actions:{edit:true} }
    },
        {
        path: '/schedule',
        name: 'schedule',
        meta: { authRequired: false },
        component: () => import('../views/pages/management/schedule'),
        props: { menu:"Company", entity: 'schedule', id: null }
    },
    {
        path: '/schedule/:id',
        name: 'scheduleEdit',
        meta: { authRequired: false },
        component: () => import('../views/pages/management/schedule'),
        props: { id: null }
    },
    {
        path: '/coaches',
        name: 'coaches',
        meta: {authRequired: false,},
        component: () => import('../views/pages/tables/advancedtable'),
        props: { menu:"coaches", entity: 'coach', addLink:"/coach", actions:{edit:true} }
    },
        {
        path: '/coach',
        name: 'coach',
        meta: { authRequired: false },
        component: () => import('../views/pages/management/coach'),
        props: { menu:"Company", entity: 'coach', id: null }
    },
    {
        path: '/coach/:id',
        name: 'coachEdit',
        meta: { authRequired: false },
        component: () => import('../views/pages/management/coach'),
        props: { id: null }
    },
    {
        path: '/members',
        name: 'members',
        meta: {authRequired: false,},
        component: () => import('../views/pages/tables/advancedtable'),
        props: { menu:"members", entity: 'member', addLink:"/member", actions:{edit:true} }
    },
    {
        path: '/member',
        name: 'member',
        meta: { authRequired: false },
        component: () => import('../views/pages/management/member'),
        props: { menu:"Company", entity: 'member', id: null }
    },
    {
        path: '/member/:id',
        name: 'memberEdit',
        meta: { authRequired: false },
        component: () => import('../views/pages/management/member'),
        props: { id: null }
    },
    {
        path: '/groups',
        name: 'groups',
        meta: {authRequired: false,},
        component: () => import('../views/pages/tables/advancedtable'),
        props: { menu:"groups", entity: 'group', addLink:"/group", actions:{edit:true} }
    },
        {
        path: '/group',
        name: 'group',
        meta: { authRequired: false },
        component: () => import('../views/pages/management/group'),
        props: { menu:"Company", entity: 'group', id: null }
    },
    {
        path: '/group/:id',
        name: 'groupEdit',
        meta: { authRequired: false },
        component: () => import('../views/pages/management/group'),
        props: { id: null }
    },
    {
        path: '/teams',
        name: 'teams',
        meta: {authRequired: false,},
        component: () => import('../views/pages/tables/advancedtable'),
        props: { menu:"teams", entity: 'team', addLink:"/team", actions:{edit:true} }
    },
    {
        path: '/team',
        name: 'team',
        meta: { authRequired: false },
        component: () => import('../views/pages/management/team'),
        props: { menu:"teams", entity: 'team', id: null }
    },
    {
        path: '/team/:id',
        name: 'teamEdit',
        meta: { authRequired: false },
        component: () => import('../views/pages/management/team'),
        props: { id: null }
    },


    {
        path: '/chat',
        name: 'Chat',
        meta: {
            authRequired: false,
        },
        component: () => import('../views/pages/chat/index')
    },
    {
        path: '/calendar',
        name: 'Calendar',
        meta: {
            authRequired: false,
        },
        component: () => import('../views/pages/calendar/index')
    },

    /****/
    {
        path: '/company/mycompany',
        name: 'company',
        meta: { authRequired: false },
        component: () => import('../views/pages/company/company'),
        props: { menu:"Company", entity: 'company', addLink:"/company/company" }
    },
    /****/

    
    /** COMPANIES ROUTES **/
    {
        path: '/company/organizations',
        name: 'organizations',
        meta: { authRequired: false },
        component: () => import('../views/pages/tables/advancedtable'),
        props: { menu:"Company", entity: 'organization', addLink:"/company/organization", actions:{view:true, edit:true} }
    },
    {
        path: '/company/organization',
        name: 'organization',
        meta: { authRequired: false },
        component: () => import('../views/pages/company/organization'),
        props: { menu:"Company", entity: 'organization', id: null }
    },
    {
        path: '/company/organization/:id',
        name: 'organizationEdit',
        meta: { authRequired: false },
        component: () => import('../views/pages/company/organization'),
        props: { id: null }
    },


    /** SITE ROUTES **/
    {
        path: '/company/sites',
        name: 'sites',
        meta: { authRequired: false },
        component: () => import('../views/pages/tables/advancedtable'),
        props: { menu:"Company", entity: 'site', addLink:"/company/site", actions:{view:true, edit:true} }
    },
    {
        path: '/company/site',
        name: 'site',
        meta: { authRequired: false },
        component: () => import('../views/pages/company/site'),
        props: { menu:"Company", entity: 'site', id: null }
    },
    {
        path: '/company/site/:id',
        name: 'siteEdit',
        meta: { authRequired: false },
        component: () => import('../views/pages/company/site'),
        props: { id: null }
    },
    /** SITE ROUTES **/

    /** DEPARTMENTS ROUTES **/
    {
        path: '/company/departments',
        name: 'departments',
        meta: { authRequired: false },
        component: () => import('../views/pages/tables/advancedtable'),
        props: { menu:"Company", entity: 'department', addLink:"/company/department", actions:{view:true, edit:true} }
    },
    {
        path: '/company/department',
        name: 'department',
        meta: { authRequired: false },
        component: () => import('../views/pages/company/department'),
        props: { menu:"Company", entity: 'department', addLink:"/company/department" }
    },
    {
        path: '/company/department/:id',
        name: 'departmentEdit',
        meta: { authRequired: false },
        component: () => import('../views/pages/company/department'),
        props: { id: null }
    },
    /** DEPARTMENTS ROUTES **/

    /** JOB POSITIONS ROUTES **/
    {
        path: '/company/jobpositions',
        name: 'job-positions',
        meta: { authRequired: false },
        component: () => import('../views/pages/tables/advancedtable'),
        props: { menu:"Company", entity: 'job-position', addLink:"/company/jobposition", actions:{view:true, edit:true} }
    },
    {
        path: '/company/jobposition',
        name: 'job-position',
        meta: { authRequired: false },
        component: () => import('../views/pages/company/jobposition'),
        props: { menu:"Company", entity: 'job-position', addLink:"/company/jobposition" }
    },
    {
        path: '/company/jobposition/:id',
        name: 'job-positionEdit',
        meta: { authRequired: false },
        component: () => import('../views/pages/company/jobposition'),
        props: { id: null }
    },
    /** JOB POSITIONS ROUTES **/

    /** EMPLOYEES ROUTES **/
    {
        path: '/company/employees',
        name: 'employees',
        meta: { authRequired: false },
        component: () => import('../views/pages/tables/advancedtable'),
        props: { menu:"Company", entity: 'employee', addLink:"/company/employee", actions:{view:true, edit:true} }
    },
    {
        path: '/company/employee',
        name: 'employee',
        meta: { authRequired: false },
        component: () => import('../views/pages/company/employee'),
        props: { menu:"Company", entity: 'employee', addLink:"/company/employee" }
    },
    {
        path: '/company/employee/:id',
        name: 'employeeEdit',
        meta: { authRequired: false },
        component: () => import('../views/pages/company/employee'),
        props: { id: null }
    },
    /** EMPLOYEES ROUTES **/

    /** USERS ROUTES **/
    {
        path: '/company/users',
        name: 'users',
        meta: { authRequired: false },
        component: () => import('../views/pages/tables/advancedtable'),
        props: { menu:"Company", entity: 'user', addLink:"/company/user", actions:{view:true, edit:true} }
    },
        {
        path: '/company/user',
        name: 'user',
        meta: { authRequired: false },
        component: () => import('../views/pages/company/user'),
        props: { menu:"Company", entity: 'user', addLink:"/company/user" }
    },
    {
        path: '/company/user/:id',
        name: 'userEdit',
        meta: { authRequired: false },
        component: () => import('../views/pages/company/user'),
        props: { id: null }
    },
    /** USERS ROUTES **/

    /** USERS ROUTES **/
    {
        path: '/company/trades',
        name: 'trades',
        meta: { authRequired: false },
        component: () => import('../views/pages/tables/advancedtable'),
        props: { menu:"Company", entity: 'trade', addLink:"/company/trade", actions:{view:true, edit:true} }
    },
        {
        path: '/company/trade',
        name: 'trade',
        meta: { authRequired: false },
        component: () => import('../views/pages/company/trade'),
        props: { menu:"Company", entity: 'trade', addLink:"/company/trade" }
    },
    {
        path: '/company/trade/:id',
        name: 'tradeEdit',
        meta: { authRequired: false },
        component: () => import('../views/pages/company/trade'),
        props: { id: null }
    },
    /** USERS ROUTES **/



    /** CUSTOMERS CUSTOMERS **/
    {
        path: '/shop/customers',
        name: 'customers',
        meta: { authRequired: false },
        component: () => import('../views/pages/tables/advancedtable'),
        props: { menu:"Shop", entity: 'customer', addLink:"/shop/customer", actions:{view:true, edit:true} }
    },
    {
        path: '/shop/customer',
        name: 'customer',
        meta: { authRequired: false },
        component: () => import('../views/pages/shop/customer'),
        props: { menu:"Shop", entity: 'customer', addLink:"/shop/customer" }
    },
    {
        path: '/shop/customer/:id',
        name: 'customerEdit',
        meta: { authRequired: false },
        component: () => import('../views/pages/shop/customer'),
        props: { id: null }
    },
    /** CUSTOMERS CUSTOMERS **/

    /** SHOP LABORS **/
    {
        path: '/shop/labors',
        name: 'labors',
        meta: { authRequired: false },
        component: () => import('../views/pages/tables/advancedtable'),
        props: { menu:"Shop", entity: 'labor', addLink:"/shop/labor", actions:{view:true, edit:true} }
    },
    {
        path: '/shop/labor',
        name: 'labor',
        meta: { authRequired: false },
        component: () => import('../views/pages/shop/labor'),
        props: { menu:"Shop", entity: 'labor', addLink:"/shop/labor" }
    },
    {
        path: '/shop/labor/:id',
        name: 'laborEdit',
        meta: { authRequired: false },
        component: () => import('../views/pages/shop/labor'),
        props: { id: null }
    },
    /** SHOP LABORS **/

    /** SHOP WORKORDERS **/
    {
        path: '/shop/workorders',
        name: 'workorders',
        meta: { authRequired: false },
        component: () => import('../views/pages/tables/advancedtable'),
        props: { menu:"Shop", entity: 'workorder', addLink:"/shop/workorder", actions:{view:true, edit:true, start:true,} }
    },
    {
        path: '/shop/workorder',
        name: 'workorder',
        meta: { authRequired: false },
        component: () => import('../views/pages/shop/workorder'),
        props: { menu:"Shop", entity: 'workorder', addLink:"/shop/workorder" }
    },
    {
        path: '/shop/workorder/:id',
        name: 'workorderEdit',
        meta: { authRequired: false },
        component: () => import('../views/pages/shop/workorder'),
        props: { id: null }
    },
    {
        path: '/shop/workorder/play/:id',
        name: 'workorderPlay',
        meta: { authRequired: false },
        component: () => import('../views/pages/shop/workorderplay'),
        props: { id: null }
    },
    /** SHOP WORKORDERS **/

    /** SHOP PURCHASE ORDERS **/
    {
        path: '/shop/purchaseorders',
        name: 'purchaseorders',
        meta: { authRequired: false },
        component: () => import('../views/pages/tables/advancedtable'),
        props: { menu:"Shop", entity: 'purchaseorder', addLink:"/shop/purchaseorder", actions:{edit:true} }
    },
    {
        path: '/shop/purchaseorder',
        name: 'purchaseorder',
        meta: { authRequired: false },
        component: () => import('../views/pages/shop/purchaseorder'),
        props: { menu:"Shop", entity: 'purchaseorder', addLink:"/shop/purchaseorder" }
    },
    {
        path: '/shop/purchaseorder/:id',
        name: 'purchaseorderEdit',
        meta: { authRequired: false },
        component: () => import('../views/pages/shop/purchaseorder'),
        props: { id: null }
    },
    /** SHOP PURCHASE ORDERS **/



    /** EVALUATIONS ROUTES **/
    {
        path: '/evaluations/evaluations',
        name: 'evaluations',
        meta: { authRequired: false },
        component: () => import('../views/pages/tables/advancedtable'),
        props: { menu:"Evaluations", entity: 'evaluation', addLink:"/evaluations/evaluation", actions:{view:true, edit:true} }
    },
        {
        path: '/evaluations/evaluation',
        name: 'evaluation',
        meta: { authRequired: false },
        component: () => import('../views/pages/evaluations/evaluation'),
        props: { menu:"Evaluations", entity: 'evaluation', addLink:"/evaluations/evaluation" }
    },
    {
        path: '/evaluations/evaluation/:id',
        name: 'evaluationEdit',
        meta: { authRequired: false },
        component: () => import('../views/pages/evaluations/evaluation'),
        props: { id: null }
    },
    {
        path: '/evaluations/evaluation/view/:id',
        name: 'evaluationView',
        meta: { authRequired: false },
        component: () => import('../views/pages/evaluations/evaluation-details'),
        props: { id: null }
    },
    /** EVALUATIONS ROUTES **/



    {
        path: '/ecommerce/products',
        name: 'products',
        meta: { authRequired: true },
        component: () => import('../views/pages/ecommerce/products')
    },
    {
        path: '/ecommerce/product-detail',
        name: 'product detail',
        meta: { authRequired: true },
        component: () => import('../views/pages/ecommerce/product-detail')
    },
    {
        path: '/ecommerce/orders',
        name: 'Orders',
        meta: { authRequired: true },
        component: () => import('../views/pages/ecommerce/orders')
    },
    {
        path: '/ecommerce/customers',
        name: 'Customers',
        meta: { authRequired: true },
        component: () => import('../views/pages/ecommerce/customers')
    },
    {
        path: '/ecommerce/cart',
        name: 'Cart',
        meta: { authRequired: true },
        component: () => import('../views/pages/ecommerce/cart')
    },
    {
        path: '/ecommerce/checkout',
        name: 'Checkout',
        meta: { authRequired: true },
        component: () => import('../views/pages/ecommerce/checkout')
    },
    {
        path: '/ecommerce/shops',
        name: 'Shops',
        meta: { authRequired: true },
        component: () => import('../views/pages/ecommerce/shops')
    },
    {
        path: '/ecommerce/add-product',
        name: 'Add-product',
        meta: { authRequired: true },
        component: () => import('../views/pages/ecommerce/add-product')
    },
    {
        path: '/email/inbox',
        name: 'Inbox',
        meta: { authRequired: true },
        component: () => import('../views/pages/email/inbox')
    },
    {
        path: '/email/read',
        name: 'Read Email',
        meta: { authRequired: true },
        component: () => import('../views/pages/email/reademail')
    },
    {
        path: '/auth/login-1',
        name: 'login-1',
        meta: { authRequired: true },
        component: () => import('../views/pages/sample-auth/login-1')
    },
    {
        path: '/auth/register-1',
        name: 'register-1',
        meta: { authRequired: true },
        component: () => import('../views/pages/sample-auth/register-1')
    },
    {
        path: '/auth/lock-screen-1',
        name: 'Lock-screen-1',
        meta: { authRequired: true },
        component: () => import('../views/pages/sample-auth/lock-screen-1')
    },
    {
        path: '/auth/recoverpwd-1',
        name: 'Recoverpwd-1',
        meta: { authRequired: true },
        component: () => import('../views/pages/sample-auth/recoverpwd-1')
    },
    {
        path: '/apps/kanban-board',
        name: 'Kanban-board',
        meta: { authRequired: true },
        component: () => import('../views/pages/kanbanboard/index')
    },
    {
        path: '/pages/starter',
        name: 'Starter Page',
        meta: { authRequired: true },
        component: () => import('../views/pages/utility/starter')
    },
    {
        path: '/pages/maintenance',
        name: 'Maintenance',
        meta: { authRequired: true },
        component: () => import('../views/pages/utility/maintenance')
    },
    {
        path: '/pages/coming-soon',
        name: 'Coming-soon',
        meta: { authRequired: true },
        component: () => import('../views/pages/utility/coming-soon')
    },
    {
        path: '/pages/timeline',
        name: 'Timeline',
        meta: { authRequired: true },
        component: () => import('../views/pages/utility/timeline/index')
    },
    {
        path: '/pages/faqs',
        name: 'Faqs',
        meta: { authRequired: true },
        component: () => import('../views/pages/utility/faqs')
    },
    {
        path: '/pages/pricing',
        name: 'Pricing',
        meta: { authRequired: true },
        component: () => import('../views/pages/utility/pricing/index')
    },
    {
        path: '/pages/error-401',
        name: 'Error-401',
        meta: { authRequired: true },
        component: () => import('../views/pages/utility/error-401')
    },
    {
        path: '/pages/error-404',
        name: 'Error-404',
        //meta: { authRequired: true },
        meta:{},
        component: () => import('../views/pages/utility/error-404')
    },
    {
        path: '/pages/error-500',
        name: 'Error-500',
        //meta: { authRequired: true },
        meta:{},
        component: () => import('../views/pages/utility/error-500')
    },
    {
        path: '/icons/font-awesome',
        name: 'Font Awesome 5',
        meta: { authRequired: true },
        component: () => import('../views/pages/icons/font-awesome/index')
    },
    {
        path: '/icons/dripicons',
        name: 'Dripicons',
        meta: { authRequired: true },
        component: () => import('../views/pages/icons/dripicons')
    },
    {
        path: '/icons/material-design',
        name: 'Material Design',
        meta: { authRequired: true },
        component: () => import('../views/pages/icons/materialdesign/index')
    },
    {
        path: '/icons/remix',
        name: 'Remix Icons',
        meta: { authRequired: true },
        component: () => import('../views/pages/icons/remix/index')
    },
    {
        path: '/ui/buttons',
        name: 'Buttons',
        meta: { authRequired: true },
        component: () => import('../views/pages/ui/buttons')
    },
    {
        path: '/ui/alerts',
        name: 'Alerts',
        meta: { authRequired: true },
        component: () => import('../views/pages/ui/alerts')
    },
    {
        path: '/ui/grid',
        name: 'Grid',
        meta: { authRequired: true },
        component: () => import('../views/pages/ui/grid')
    },
    {
        path: '/ui/cards',
        name: 'Cards',
        meta: { authRequired: true },
        component: () => import('../views/pages/ui/cards')
    },
    {
        path: '/ui/carousel',
        name: 'Carousel',
        meta: { authRequired: true },
        component: () => import('../views/pages/ui/carousel')
    },
    {
        path: '/ui/dropdowns',
        name: 'Dropdowns',
        meta: { authRequired: true },
        component: () => import('../views/pages/ui/dropdowns')
    },
    {
        path: '/ui/images',
        name: 'Images',
        meta: { authRequired: true },
        component: () => import('../views/pages/ui/images')
    },
    {
        path: '/ui/modals',
        name: 'Modals',
        meta: { authRequired: true },
        component: () => import('../views/pages/ui/modals')
    },
    {
        path: '/ui/rangeslider',
        name: 'Range - slider',
        meta: { authRequired: true },
        component: () => import('../views/pages/ui/rangeslider')
    },
    {
        path: '/ui/progressbar',
        name: 'Progressbar',
        meta: { authRequired: true },
        component: () => import('../views/pages/ui/progressbars')
    },
    {
        path: '/ui/sweet-alert',
        name: 'Sweet-alert',
        meta: { authRequired: true },
        component: () => import('../views/pages/ui/sweet-alert')
    },
    {
        path: '/ui/tabs-accordion',
        name: 'Tabs & Accordion',
        meta: { authRequired: true },
        component: () => import('../views/pages/ui/tabs-accordions')
    },
    {
        path: '/ui/typography',
        name: 'Typography',
        meta: { authRequired: true },
        component: () => import('../views/pages/ui/typography')
    },
    {
        path: '/ui/video',
        name: 'Video',
        meta: { authRequired: true },
        component: () => import('../views/pages/ui/video')
    },
    {
        path: '/ui/general',
        name: 'General',
        meta: { authRequired: true },
        component: () => import('../views/pages/ui/general')
    },
    {
        path: '/ui/lightbox',
        name: 'Lightbox',
        meta: { authRequired: true },
        component: () => import('../views/pages/ui/lightbox')
    },
    {
        path: '/ui/notification',
        name: 'Notification',
        meta: { authRequired: true },
        component: () => import('../views/pages/ui/notification')
    },
    {
        path: '/ui/rating',
        name: 'Rating',
        meta: { authRequired: true },
        component: () => import('../views/pages/ui/rating')
    },
    {
        path: '/ui/session-timeout',
        name: 'Session Timeout',
        meta: { authRequired: true },
        component: () => import('../views/pages/ui/session-timeout')
    },
    {
        path: '/form/elements',
        name: 'Form Elements',
        meta: { authRequired: true },
        component: () => import('../views/pages/forms/elements')
    },
    {
        path: '/form/validation',
        name: 'Form validation',
        meta: { authRequired: true },
        component: () => import('../views/pages/forms/validation')
    },
    {
        path: '/form/advanced',
        name: 'Form Advanced',
        meta: { authRequired: true },
        component: () => import('../views/pages/forms/advanced')
    },
    {
        path: '/form/editor',
        name: 'CK Editor',
        meta: { authRequired: true },
        component: () => import('../views/pages/forms/ckeditor')
    },
    {
        path: '/form/uploads',
        name: 'File Uploads',
        meta: { authRequired: true },
        component: () => import('../views/pages/forms/uploads')
    },
    {
        path: '/form/wizard',
        name: 'Form Wizard',
        meta: { authRequired: true },
        component: () => import('../views/pages/forms/wizard')
    },
    {
        path: '/form/mask',
        name: 'Form Mask',
        meta: { authRequired: true },
        component: () => import('../views/pages/forms/mask')
    },
    {
        path: '/tables/basic',
        name: 'Basic Tables',
        meta: { authRequired: true },
        component: () => import('../views/pages/tables/basictable')
    },
    {
        path: '/tables/advanced',
        name: 'Advanced Tables',
        meta: { authRequired: true },
        component: () => import('../views/pages/tables/advancedtable')
    },
    {
        path: '/charts/apex',
        name: 'Apex chart',
        meta: { authRequired: true },
        component: () => import('../views/pages/charts/apex')
    },
    {
        path: '/charts/chartjs',
        name: 'Chartjs chart',
        meta: { authRequired: true },
        component: () => import('../views/pages/charts/chartjs/index')
    },
    {
        path: '/charts/chartist',
        name: 'Chartist chart',
        meta: { authRequired: true },
        component: () => import('../views/pages/charts/chartist')
    },
    {
        path: '/charts/echart',
        name: 'Echart chart',
        meta: { authRequired: true },
        component: () => import('../views/pages/charts/echart/index')
    },
    {
        path: '/maps/google',
        name: 'Google Maps',
        meta: { authRequired: true },
        component: () => import('../views/pages/maps/google')
    },
    {
        path: '/maps/leaflet',
        name: 'Leaflet Maps',
        meta: { authRequired: true },
        component: () => import('../views/pages/maps/leaflet/index')
    },
]
