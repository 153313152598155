<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script type="text/javascript" src="cordova.js"></script>
<script>
import appConfig from "@/app.config";

export default {
  name: "app",
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    }
  },
  mounted(){
    document.addEventListener("offline", this.onOffline, false);
    document.addEventListener("online", this.onOnline, false);
    document.addEventListener("deviceready", this.onDeviceReady, false);
    
  },
  methods:{
    onDeviceReady(){
      let platform = device.platform;

      if(platform){
        let anytimeLaunched = localStorage.getItem('anytimeLaunched');
        if(!anytimeLaunched){
          this.$router.push('/onboarding').catch((e) => e);
        }
      }
    },
    onOffline(){
      this.$bvToast.toast(
        'Seems like you have no internet connection.',
        {
          title: 'Internet connection',
          variant: 'warning',
          solid: true
        }
      );
    },
    onOnline(){
      this.$bvToast.toast(
        "You're connected to internet again.",
        {
          title: 'Internet connection',
          variant: 'info',
          solid: true
        }
      );
    }
  }
};
</script>